import { getCookie }        from "~/helpers/cookies"
import { toggleElement }    from '~/client/vanilla-components/dropdown-screen'

window.addEventListener('DOMContentLoaded', () => {
    const menuContainerList = document.querySelectorAll<HTMLElement>('.footer-menu__item-main')
    const displayFullListCities = document.querySelector<HTMLElement>('.footer__short-list-cities--expand-btn')
    const collapseFullListCities = document.querySelector<HTMLElement>('.footer__full-list-cities--сollapse-btn')

    if (menuContainerList.length && displayFullListCities && collapseFullListCities) {
        menuContainerList.forEach((el: HTMLElement) => {
            el.addEventListener('click', () => {
                el.classList.toggle('footer-menu__item-main--active')

                if (el.closest('.footer__full-list-cities--item')) {
                    const parents = document.querySelectorAll<HTMLElement>('.footer__full-list-cities--item .footer-menu__item')

                    if (!parents.length) return void 0

                    parents.forEach((el: HTMLElement) => {
                        el.classList.toggle('!flex')
                    })
                }
            })
        })

        displayFullListCities.addEventListener('click', function() {
            let shortListCities = this.closest<HTMLElement>('.footer__short-list-cities')
            let fullListCities = document.querySelector<HTMLElement>('.footer__full-list-cities')

            if (!shortListCities || !fullListCities) return void 0

            shortListCities.classList.toggle('footer__short-list-cities--hide')
            fullListCities.classList.toggle('footer__full-list-cities--hide')
        })

        collapseFullListCities.addEventListener('click', function() {
            const shortListCities = document.querySelector<HTMLElement>('.footer__short-list-cities')
            const fullListCities = this.closest<HTMLElement>('.footer__full-list-cities')

            if (!shortListCities || !fullListCities) return void 0

            shortListCities.classList.toggle('footer__short-list-cities--hide')
            fullListCities.classList.toggle('footer__full-list-cities--hide')
        })
    }

    const host = document.location.host

    const cookieNameNoShow = 'noShowSubscription' + (host.includes('home24') ? 'H24' : 'EO');
    const cookieNoShow = getCookie(cookieNameNoShow);

    if (!cookieNoShow) {
        const cookieName = 'cookieshowSubscription' + (host.match(/home24/) ? 'H24' : 'EO');
        const cookiePolicyAccepted = getCookie(cookieName)

        if (!cookiePolicyAccepted || cookiePolicyAccepted != 'false') {
            const date = new Date()
            date.setTime(date.getTime() + (3 * 24 * 60 * 60 * 1000))
            const expiryDate = `expires=${date.toUTCString()}`
            if (!cookiePolicyAccepted) {
                if(window.location.pathname == '/catalog/') {
                    showPopup(expiryDate, cookieName);
                } else {
                    document.cookie = `${cookieName}=1; path=/; ${expiryDate}`
                }
            } else {
                if (cookiePolicyAccepted == '2') {
                    showPopup(expiryDate, cookieName);
                } else if(cookiePolicyAccepted == '1') {
                    document.cookie = `${cookieName}=2; path=/; ${expiryDate}`
                }
            }
        }
    }
}, {once: true})

const showPopup = (expiryDate: string, cookieName: string) => {
    const subscriptionPopUp = document.querySelector<HTMLElement>('.footer-subscription')

    if (subscriptionPopUp) {
        setTimeout(() => {

            const currentActivePopup = document.querySelector<HTMLElement>('.dropdown-screen--opened .dropdown-screen__additional-overlay')
            const currentActiveOuterPopup = document.querySelector<HTMLElement>('.dropdown-screen--opened .dropdown-screen__outer')

            if (window.localStorage.getItem('delay') === 'delay' && !currentActivePopup && !currentActiveOuterPopup) {
                toggleElement(subscriptionPopUp, true, true)
                document.cookie = `${cookieName}=false; path=/; ${expiryDate};`
                window.localStorage.removeItem('delay')

            } else {
                window.localStorage.setItem('delay', 'delay')
                showPopup(expiryDate, cookieName)

            }

        }, 2500)
    }
}
