import throttle from "~/helpers/throttle"

let _previousOpenedItem: HTMLElement | null = null

window.addEventListener('load', () => {
    initListeners()
}, {once: true})

const initListeners = () => {
    const breadcrumbsItemsList = document.querySelectorAll<HTMLElement>('.breadcrumbs__item')

    if (!breadcrumbsItemsList.length) return void 0

    window.addEventListener('click', handleItemEvent)
    breadcrumbsItemsList.forEach(el => {
        el.addEventListener('mouseenter', handleItemEvent)
        el.addEventListener('mouseleave', handleItemEvent)
    })
    window.addEventListener('scroll', throttle(handleScroll, 1000), {passive: true})
}

const handleItemEvent = (e: Event) => {
    const isMobile = window.innerWidth < 1200
    if (isMobile && (e.type === 'mouseenter' || e.type === 'mouseleave')) return void 0

    const target = e.target as HTMLElement
    let dropdown: HTMLElement | null = _previousOpenedItem ? _previousOpenedItem.querySelector<HTMLElement>('.breadcrumbs-item-dropdown') : null

    const hasDropdown = Boolean(target.querySelector('.breadcrumbs-item-dropdown'))
    if (isMobile && e.type === 'click' && !hasDropdown) {
        const link = target.querySelector<HTMLElement>('.breadcrumbs__item-link')
        const href = link?.getAttribute('href')

        if (href) {
            window.location.href = href
        }
    }

    if (!target.classList.contains('breadcrumbs__item'))  {
        _previousOpenedItem?.classList.remove('breadcrumbs__item--opened')

        toggleElement(dropdown, false)
    } else {
        if (_previousOpenedItem === target) {
            if (target.classList.contains('breadcrumbs__item--opened') || e.type === 'mouseleave') {
                toggleElement(dropdown, false)
                target.classList.remove('breadcrumbs__item--opened')
            } else {
                toggleElement(dropdown, true)
                target.classList.add('breadcrumbs__item--opened')
            }
        } else {
            toggleElement(dropdown, false)
            _previousOpenedItem?.classList.remove('breadcrumbs__item--opened')

            dropdown = target.querySelector<HTMLElement>('.breadcrumbs-item-dropdown')
            toggleElement(dropdown, true)

            target.classList.add('breadcrumbs__item--opened')
            _previousOpenedItem = target
        }
    }
}

const toggleElement = (el: HTMLElement | null, state: boolean) => {
    if (!el || !el.parentElement) return void 0

    if (state) {
        const offset = 24
        const winW = window.innerWidth
        const {x ,y, right} = el.getBoundingClientRect()
        const {width} = el.parentElement.getBoundingClientRect()

        if (x <= offset) {
            el.style.left = offset + 'px'
            el.style.right = 'auto'
        } else if (right >= (winW - offset)) {
            el.style.left = 'auto'
            el.style.right = offset + 'px'
        } else {
            el.style.left = x + 'px'
            el.style.right = 'auto'
        }
        el.style.minWidth = `${width + 8}px`
        el.style.top = y + 'px'
    } else {
        el.style.left = '0px'
        el.style.right = 'auto'
        el.style.top = '100%'
    }
}

const handleScroll = () => {
    if (!_previousOpenedItem || !_previousOpenedItem.classList.contains('breadcrumbs__item--opened')) return void 0

    let event = 'mouseleave'
    if (window.innerWidth < 1200) event = 'click'

    _previousOpenedItem.dispatchEvent(new Event(event, {bubbles: true}))
}
