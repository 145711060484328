import getEnv, { EnvMode, EnvDomain } from '~/helpers/get-env'
let initInProgress = false

window.addEventListener('load', () => {
    window.addEventListener('mousemove', initThirdParty)
    window.addEventListener('scroll', initThirdParty, {passive: true})
    window.addEventListener('click', initThirdParty)
}, {once: true})

const initThirdParty = () => {
    if (initInProgress) return void 0

    initInProgress = true

    createMarquiz()
    createRecaptcha()
    createDiginetica()

    window.removeEventListener('mousemove', initThirdParty)
    window.removeEventListener('scroll', initThirdParty)
    window.removeEventListener('click', initThirdParty)
}

const createRecaptcha = () => {
    const scriptTag = document.createElement('script')
    scriptTag.src = "https://www.google.com/recaptcha/api.js?render=6LfRlIkpAAAAAH3bqlbbW1FrE89Xtb0ho5TZNEPI"
    scriptTag.async = true
    document.head.appendChild(scriptTag)
}

const createDiginetica = () => {
    const env = getEnv()

    if (env.mode !== EnvMode.PROD) return void 0

    const scriptTag = document.createElement('script')

    let src = '//cdn.diginetica.net/1954/client.js?ts=' + Date.now()
    if (env.domain === EnvDomain.HOME24) src = '//cdn.diginetica.net/2700/client.js'

    scriptTag.src = src
    scriptTag.async = true
    scriptTag.defer = true

    document.body.appendChild(scriptTag)
}

const createMarquiz = () => {
    const options = {
        host: '//quiz.marquiz.ru',
        region: 'eu',
        autoOpen: false,
        autoOpenFreq: 'once',
        openOnExit: false,
        disableOnMobile: false
    }

    const scriptTag = document.createElement('script')
    scriptTag.src = '//script.marquiz.ru/v2.js'
    scriptTag.async = true
    scriptTag.onload = () => {
        if (document.readyState !== 'loading') {
            window.Marquiz.init(options);
        } else {
            document.addEventListener('DOMContentLoaded', () => {
                window.Marquiz.init(options);
            }, {once: true})
        }
    }

    document.head.appendChild(scriptTag)
}

export default createRecaptcha
