import { isIOS } from '~/client/vanilla-components/detect'
import useStorage from '~/helpers/use-storage'

if (isIOS) {
    const storage = useStorage<{ [key: string]: number }>('scroll', 'session')
    const isProductLast = useStorage<boolean>('is-product-last', 'session')
    const { href } = window.location

    document.addEventListener('DOMContentLoaded', () => {
        const isCategoryPage = document.body.classList.contains('page-category')

        if (!isCategoryPage || !isProductLast || !storage) return

        window.scroll({ top: storage.data[href], behavior: 'smooth' })

        isProductLast.data = document.body.classList.contains('page-product')

        if (isCategoryPage) window.addEventListener('scroll', () => {
            storage.data = {
                ...storage.data,
                [href]: window.scrollY,
            }
        }, {passive: true})
    }, {once: true})
}
