import 'keen-slider/keen-slider.min.css'

import bem from "bem-ts"
import KeenSliderHelper from '~/classes/keen-slider-helper'
import { googleDataMarketingBanner, googleDataMarketingBannerClick  } from '~/helpers/google-datalayer'

const _bemBlock = bem("header-banner", { strict: false })

const header: HTMLElement                           = document.getElementById('header') as HTMLElement
const headerBanner: HTMLElement                     = document.getElementById(`${_bemBlock()}`) as HTMLElement
const headerPromoMessage: HTMLElement               = document.querySelector(`.${_bemBlock()}__copied-info`) as HTMLElement
const headerPromoBanner: NodeListOf<HTMLElement>    = document.querySelectorAll('[data-promo]') as NodeListOf<HTMLElement>

window.addEventListener('DOMContentLoaded', () => {
    setTimeout(() => {
        if (headerBanner) {
            document.addEventListener('swipeBlockOpen', (e: Event) => headerLock(e))
            document.addEventListener('swipeBlockClose', (e: Event) => headerLock(e))
            document.addEventListener('dropdownOpen', (e: Event) => headerLock(e))
            document.addEventListener('dropdownClose', (e: Event) => headerLock(e))
            document.addEventListener('toggleFullscreen', (e: Event) => headerLock(e))
        } else {
            header.classList.add('header--fixed')
        }

        const container         = document.getElementById('banners-header') as HTMLElement
        const slides            = container?.querySelectorAll('.keen-slider__slide') as NodeListOf<HTMLElement>
        const initialSlide      = Math.floor(Math.random() * slides?.length)

        if (!container) return void 0

        initClickListener(container)

        const slider = new KeenSliderHelper(
            container,
            {
                initial: initialSlide,
                loop: false,
                drag: false,
                slides: {
                    perView: 1,
                },
            },
        )

        slider.createAdditionalMarkup({
            popup: {
                enable: true,
                selector: '.slider-banners__adv-button'
            }
        })

        slider.instance.slides.forEach(element => {
            if(element.classList.contains(`keen-slider__slide--current`)) googleDataMarketingBanner(element)
        })
    })

    // копирование промокода с баннера
    headerPromoBanner.forEach((banner: HTMLElement) => {
        banner?.addEventListener('click', () => {
            const promocode = banner.dataset.promo as string

            navigator.clipboard.writeText(promocode)
            if (headerPromoMessage?.classList?.contains(`${_bemBlock()}__copied-info--opened`) === false) {
                headerPromoMessage?.classList?.add(`${_bemBlock()}__copied-info--opened`)

                setTimeout(()=> headerPromoMessage?.classList?.remove(`${_bemBlock()}__copied-info--opened`), 3000)
            }
        })
    })
}, {once: true})

window.addEventListener('scroll', () => {
    headerPromoMessage?.classList?.remove(`${_bemBlock()}__copied-info--opened`)
}, {passive: true})

function headerLock(e: Event) {
    const eventType     = e.type
    const target        = e.target as HTMLElement|null

    if (!target || target?.classList.contains('dropdown-screen--cookie-policy') || target?.classList.contains(`dropdown-screen--header-location`)) {
        return void 0
    }

    const swiperBlock   = target?.querySelector<HTMLElement>('.the-cart__content-wrapper')
    const closeBtn      = target?.querySelector<HTMLElement>('.dropdown-close')

    // отступ от верха шапки до начала страницы
    const headerSpacing = header.getBoundingClientRect().top > 0 ? header.getBoundingClientRect().top : 0
    // размер шапки + отображаемой области баннера
    const topBoundary = header.getBoundingClientRect().bottom > 0 ? header.getBoundingClientRect().bottom : header.getBoundingClientRect().height

    // Атрибут у DropdownScreenModal.vue
    const preventHeaderFix = !!target.dataset.preventHeaderFix

    switch (eventType) {
    case `dropdownOpen`:
        if (!preventHeaderFix) {
            header.classList.add('header--fixed')
            header.style.top = `${headerSpacing}px`
        }
        break
    case `dropdownClose`:
        if (!preventHeaderFix) {
            header.classList.remove('header--fixed')
            header.style.top = `0px`
        }
        break
    case `swipeBlockOpen`:
        if (swiperBlock) swiperBlock.style.height = getAviableAreaHeight(topBoundary, target)
        break
    case `swipeBlockClose`:
        if (swiperBlock) swiperBlock.removeAttribute('style')
        break
    default:
        break
    }

    // расчет положения dropdown
    setOuterBlockPosition(topBoundary, target, eventType)

    // до перерработки шаблонов dropdown пока так (крестик в галлереи)
    if (closeBtn && target?.classList.contains('dropdown-screen--gallery')) closeBtn.style.top = `${topBoundary + 16}px`
}

function setOuterBlockPosition(topBoundary: number, target: HTMLElement, eventType: string) {
    const outerBlock = target?.querySelector<HTMLElement>('.dropdown-screen__outer')

    if (!outerBlock) return
    if (eventType !== 'toggleFullscreen' && target.dataset.relation === 'amazzing_filter'){
        outerBlock.style.top = `4rem`
        outerBlock.style.height = `calc(100%-8rem)`

        return
    }

    outerBlock.style.top = `${topBoundary}px`
    outerBlock.style.height = getAviableAreaHeight(topBoundary, target)
}

// смотрим что вызывает открыти и считае доступную область для dropdown в зависимости от разрешения
// если нужно что-то особое по высоте, то считаем тут)
function getAviableAreaHeight(topBoundary: number, target: HTMLElement) {
    const mobileMenuHeight =  window.screen.width >= 960 ? 0 : 46
    let aviableAreaHeight  = ''

    if (target?.classList.contains('dropdown-screen--filter')) aviableAreaHeight = `calc(${window.innerHeight}px - (${topBoundary}px + ${mobileMenuHeight}px))`
    if (target?.classList.contains('swipe-block--cart')) aviableAreaHeight = `calc(${window.innerHeight}px - (${topBoundary}px + ${mobileMenuHeight}px) - 72px)`

    return aviableAreaHeight
}

const initClickListener = (target: HTMLElement) => {
    target.addEventListener('click', handleClick)
}

const handleClick = (e: Event) => {
    const eventTarget = e.target as HTMLElement
    const currentSlide = eventTarget.closest<HTMLElement>('.keen-slider__slide--current')

    if (currentSlide) {
        googleDataMarketingBannerClick(currentSlide)
    }
}
