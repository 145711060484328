import { fetchRequest } from "~/helpers/fetch"
import { WindowCustom } from "~/interfaces/window"
import { yandexDatalayerForm }     from '~/helpers/yandex-datalayer'

let comparedProductsIds: number[] = []

window.addEventListener('DOMContentLoaded', () => {
    setIdsFromWindow()
    compareButtonsStatusRefresh()
    delegateClickListeners()
}, {once: true})

const setIdsFromWindow = () => {
    // Модуль с бэка пропихивает значения в window, а значит пока не переписали и бэк, оставляю так

    const windowCustom: WindowCustom = window

    if (windowCustom.comparedProductsIds) comparedProductsIds = windowCustom.comparedProductsIds
}

const delegateClickListeners = () => {
    window.addEventListener('click', (e: Event) => {
        let target = <HTMLElement>e.target

        if (!target.classList.contains('button-compare-add')) {
            const buttonCompareAddContainer = target.closest<HTMLElement>('.button-compare-add')

            if (!buttonCompareAddContainer) return void 0

            target = buttonCompareAddContainer

        }

        const productId = target.dataset.idProduct

        if (!productId) return void 0
        yandexDatalayerForm('comparison')
        addToCompare(productId)
    })
}

const compareButtonsStatusRefresh = () => {
    const compareButtons = document.querySelectorAll<HTMLElement>('.button-compare-add')

    if (!compareButtons.length) return void 0

    compareButtons.forEach((el: HTMLElement) => {
        const currentId = el.dataset.idProduct
        const serviceButtonElement = el.querySelector<HTMLElement>('.service-button')

        if (!currentId) return void 0

        if (comparedProductsIds.includes(Number(currentId))) {
            el.classList.add('favorite--active')
            serviceButtonElement?.classList.add('service-button--active')
        } else {
            el.classList.remove('favorite--active')
            serviceButtonElement?.classList.remove('service-button--active')
        }
    })
}

const updateMobilePanel = () => {
    const mobilePanelComparisonBadgeElement = document.querySelector<HTMLElement>('.mobile-navigation__item--comparison .service-button__badge')

    if (!mobilePanelComparisonBadgeElement) return void 0

    mobilePanelComparisonBadgeElement.innerText = String(comparedProductsIds.length)

    comparedProductsIds.length
        ? mobilePanelComparisonBadgeElement.classList.remove('service-button__badge--hidden')
        : mobilePanelComparisonBadgeElement.classList.add('service-button__badge--hidden')
}

const updateHeaderPanel = () => {
    const headerPanelComparisonBadgeElement = document.querySelector<HTMLElement>('.header__user-links-item--comparison .comparison .service-button__badge')

    if (!headerPanelComparisonBadgeElement) return void 0

    headerPanelComparisonBadgeElement.innerText = String(comparedProductsIds.length)

    comparedProductsIds.length
        ? headerPanelComparisonBadgeElement.classList.remove('service-button__badge--hidden')
        : headerPanelComparisonBadgeElement.classList.add('service-button__badge--hidden')
}

const addToCompare = async (productId: string) => {
    const action = comparedProductsIds.includes(Number(productId)) ? 'remove' : 'add'
    const url = `/module/express_compare/compare?ajax=1&action=${action}&id_product=${productId}`

    try {
        const response = await fetchRequest(url, {
            method: 'GET',
        })

        if (action === 'add' && response !== 0) {
            comparedProductsIds.push(Number(productId))
            compareButtonsStatusRefresh()
            updateMobilePanel()
            updateHeaderPanel()
        } else if (action === 'remove' && response !== 0) {
            comparedProductsIds.splice(
                comparedProductsIds.indexOf(Number(productId)),
                1
            )
            compareButtonsStatusRefresh()
            updateMobilePanel()
            updateHeaderPanel()
        }
    } catch (error) {
        console.error(error)
    }
}
