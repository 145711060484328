import AjaxProductLoader from '~/classes/tabs-block/ajax-product-loader'

window.addEventListener('DOMContentLoaded', () => {
    AjaxProductLoader.initLoaders('.button__product-collection-loader')

    const showMoreProductButtons = document.querySelectorAll('.button__product-price-list-loader') as NodeListOf<HTMLButtonElement>

    if (!showMoreProductButtons.length) return void 0

    showMoreProductButtons.forEach((button: HTMLButtonElement) =>
        button.addEventListener('click', (event: Event) => AjaxProductLoader.loadPriceListProducts(event)))
}, {once: true})
