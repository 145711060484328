window.addEventListener('DOMContentLoaded', () => {
    const layoutContainer = document.querySelector<HTMLElement>('.layout')

    if (!layoutContainer) return void 0

    layoutContainer.addEventListener('click', (e) => {
        const target = e.target as HTMLFormElement

        if (!target.classList.contains('tags-all__show-all')) return void 0

        const groupContainer = target.closest('.tags-all__group')

        if (!groupContainer) return void 0

        groupContainer.classList.toggle('tags-all__group--opened')

        if (groupContainer.classList.contains('tags-all__group--opened')) {
            target.innerText = 'Скрыть'
        } else {
            target.innerText = 'Показать все'
        }
    })
}, {once: true})
