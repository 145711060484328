import { isInView } from '~/helpers/is-in-view'
import { isIOS } from '~/client/vanilla-components/detect'

if (isIOS) {
    document.addEventListener('scroll', () => {
        const input = document.querySelector<HTMLInputElement>('input:focus, textarea:focus')
        if (input && !isInView(input, 100)) {
            input.blur()
        }
    }, {passive: true})
}
